<template>
  <div class="agents-documents">
      <div class="header">
          <h1 v-if="current_agent">שלום {{current_agent.display_name}}</h1>
      </div>
      <div class="wrapper">
        <div v-if="files_data.length>0" class="agent-files">
                <table id="customers">
                    <tr>
                        <th>מס</th>
                        <th>שם הקובץ</th>
                        <th>נוצר בתאריך</th>
                        <th>מעלה</th>
                        <th>כלים</th>
                    </tr>
                    <template v-for="(file,index) in files_data" :key="file.filePath">
                        <tr>
                            <td>{{index+1}}</td>
                            <td>{{file.file_name}}</td>
                            <td>{{new Date(file.createdAt).toLocaleDateString('he')}}</td>
                            <td>{{file.uploaded_name}}</td>
                            <td class="tools">
                                <a :href="file.url" target="_blank" ><i class="material-icons download-icon">file_download</i></a>
                            </td>
                        </tr>
                    </template>
                </table>
        </div>
        <div v-if="files_data.length==0" class="no-results">
            <h1>אין מסמכים להצגה</h1>
            <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fno-results_375x375.png?alt=media&token=f0d2d373-1d86-4749-9cf5-55c594b228a2" alt="">
        </div>
      </div>
  </div>
</template>

<script>
import { computed , onMounted, ref } from '@vue/runtime-core'
import store from '../../store'
import {projectFirestore} from '../../firebase/config'

export default {
       setup(){
        
        const files_data = ref([])
        const current_agent=ref(computed(()=>{
            if(store.getters.user){
                return store.getters.user
            }
        }))
        

        const get_files_from_db_from = async()=>{
            const doc = await projectFirestore.collection('Users').doc(current_agent.value.uid).get()
            console.log(doc.data());
            if(doc.data().uploaded_files){
                files_data.value = doc.data().uploaded_files
                console.log(files_data.value);
            }else{
                files_data.value=[]
                console.log(files_data.value);
            }
        }
          
        onMounted(async()=>{
            setTimeout(async()=>{
                await get_files_from_db_from()  
            },1500)
           
        })
        return{files_data,current_agent}
    }
}
</script>

<style scoped>
    .agents-documents{
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .header{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .wrapper{
        margin: 0 auto;
        width: 100%;
        max-width: 700px;
        height: 90%;

    }
    #customers {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        color: #333;
    }

    #customers td, #customers th {
        text-align:center;
        border: 1px solid #ddd;
        padding: 8px;
    }

    #customers tr{background-color: #f2f2f2;}

    #customers th {
    background-color: #635CD0;
    color: white;
    }
    i{
        cursor: pointer;
    }
    .delete-icon{
        color: crimson;
    }
    .download-icon{
        margin-right: 5px;
    }
    .download-icon:hover{
        color: darkorange;
    }
    .no-results{
        width: 100%;
        height: 90%;
        background:var(--secondary) ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
</style>